import React, { useState } from "react";
import {
  saveRelatedEquipmentFilter,
  saveKnowledgeSourceFilter,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setTotalDoc,
  setEquipment,
  setTypes,
} from "../../redux/actions";
import { connect } from "react-redux";
import Filter from "../Filter/Filter";
import RaiseAFeedback from "../raiseAFeedback/raiseAFeedback";

const SearchResultOptions = (props) => {
  const [showFilterByOpenClose, setshowFilterByOpenClose] = useState("hide");
  const [knowledgeSource, setKnowledgeSource] = useState("");
  const [relatedEquipment, setRelatedEquipment] = useState("");
  const [newFilter, setnewFilter] = useState(false);
  const [featureFlag, setFeatureFlag] = useState(props.featureFlag);
  const [show, setShow] = useState(0);
  const [enableFeedbackForm, setEnableFeedbackForm] = useState(false);

  const [rerender, setrerender] = useState(false);
  const rendering = () => {
    setrerender(!rerender);
  };

  const [filter, setFilter] = useState("hide");
  const handleFilter = () => {
    if (filter === "hide") {
      setFilter("show");
      setshowFilterByOpenClose("show");
    } else {
      setFilter("hide");
      setshowFilterByOpenClose("hide");
    }
  };
  function applySeries(type, value) {
    if (value !== "") {
      props.applyFilter(type, value);
    } else {
      props.applyFilter(type, []);
    }
    setnewFilter(true);
  }

  const showFeedbackForm = () => {
    setEnableFeedbackForm((enableFeedbackForm) => !enableFeedbackForm);
  };
  return (
    <div className="flex mt-6 h-[60px]">
      <div className="flex">
        <span className="text-[10px] md:text-[14px] leading-[10px] md:leading-[23px] font-medium ml-14">
          Most Relevant
        </span>
        <div className="h-1 w-1 bg-slate-500 rounded-lg mt-3 ml-1 mr-1 hidden md:block "></div>
        {/* <span className="ml-1 text-[10px]  md:text-[14px] leading-[10px] md:leading-[23px]">
          You have searched for "{props.searchedWord}" here are the results (
          {knowledgeSource !== "" || relatedEquipment !== ""
            ? props.filteroutputlength
            : JSON.parse(props?.searchResult).length}
          )
        </span> */}
        <span className="ml-1 text-[10px]  md:text-[14px] leading-[10px] md:leading-[23px]">
          You have searched for "{props.searchedWord}" here are the results
        </span>
      </div>
      {/* filter with dropdown in which the dropdown contains the option provided from backend */}
      <div className="flex ml-auto mr-14">
        <div>
          {props.totalDoc !== 0 && (
            <button
              onClick={(e) => handleFilter()}
              id="filterButton"
              className={`flex p-1 w-20 h-10 mr-1 ml-auto ${
                showFilterByOpenClose === "hide"
                  ? "text-[#0971CE] bg-white"
                  : "text-white bg-[#0971CE]"
              }  border-2 rounded-md`}
            >
              <span className="ml-3 mt-1 font-inter"> Filter</span>
            </button>
          )}

          <div
            className={` absolute ${
              featureFlag["Q&A engine"]
                ? "top-[48.5rem] right-[18.6rem]"
                : "top-[18.5rem] right-[20.6rem]"
            } md:top-[17.5rem]  md:right-[48.6rem]  h-0 w-0 z-50 mt-3 md:mt-0 ml-6 ${
              filter === "hide" ? "hidden" : "block"
            }`}
          >
            <div
              className={
                featureFlag["Q&A engine"]
                  ? `w-[23rem] lg:w-[30rem]  p-2 pl-3 pr-3 bg-white text-black rounded-lg border shadow-lg mt-12 sm:mt-0  ml-[-5rem]   
              md:ml-[-9.7rem]`
                  : `w-[23rem] lg:w-[30rem]  p-2 pl-3 pr-3 bg-white text-black rounded-lg border shadow-lg mt-12 sm:mt-0  ml-[-5rem]   
              md:ml-[15.7rem]`
              }
            >
              <Filter
                equipment={props.documentequipments}
                type={props.documenttypes}
                applySeries={applySeries}
                rerender={rendering}
              />
            </div>
          </div>
        </div>
      </div>

      {/* {props.totalDoc === 0 && <RaiseAFeedback />} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    searchResult: state.searchResult,
    searchResultAuthDocs: state.searchResultAuthDocs,
    filteroutputlength: state.filteroutputlength,
    documentequipments: state.documentequipments,
    documenttypes: state.documenttypes,
    appliedFilter: state.appliedFilter,
    newFilter: state.newFilter,
    featureFlag: state.featureFlag,
    totalDoc: state.totalDoc,
  };
};

export default connect(mapStateToProps, {
  saveKnowledgeSourceFilter,
  saveRelatedEquipmentFilter,
  saveSearchResultAuthDocs,
  saveSearchResult,
  setTotalDoc,
  setEquipment,
  setTypes,
})(SearchResultOptions);
