import React from "react";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import Navbar from "../components/Navbar/Navbar";
import WasHelpFull from "../components/WasThisHelpful/WasHelpFull";
import ResultSearchDetailBox from "../components/SearchDetail/ResultSearchDetailBox";
import { getProcedure, getSasUrl, getAllPinedMessage, getAllPinnedDoc } from "../components/apiCall";
import {
  storeDocDetail,
  storeOutline,
  storeFileLink,
  setPinnedSearches
} from "../redux/actions";

const SearchResultDetailPage = (props) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { id, type } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch the document using the ID
    let docData;
    const fetchDocument = async () => {
      try {
        const response = await getProcedure({
          params: { id, type },
        });
        if (type === 'ingested') {
          docData = {
            result: response.data,
            id: response.data.id,
            rootId: response.data.root?.docs[0].id,
            title: response.data.root?.docs[0].title,
            file_link: response.data.root?.docs[0].file_link,
            equipment: response.data.root?.docs[0].equipment[0],
            type: response.data.root?.docs[0].type,
            fileName: response.data.root?.docs[0].file_name,
            meta: response.data.meta,
            dual: response.data.root?.docs[0].dual
          }
          console.log(docData, 'new');
          getSasUrl({
            url: docData.file_link,
            directory: response.data.root.docs[0].directory,
          }).then((res) => {
            dispatch(storeFileLink(res.data.convertedUrl));
          })
          dispatch(storeDocDetail(docData));
          dispatch(storeOutline(JSON.stringify(response.data.root?.docs[0].outline)));
        } else {
          dispatch(storeDocDetail({
            authorData: JSON.stringify(response.data),
            authdoc: true,
          }));
        }
        // set pinned docs
        let docfrommongo = [];
        let docfromsolr = [];
        const getAllPinedMessageRes = await getAllPinedMessage();
        console.log("getAllPinedMessageRes", getAllPinedMessageRes);
        const idArray = getAllPinedMessageRes.data.data.map(
          (item) => item.documentId
        );
        docfrommongo = getAllPinedMessageRes.data.data;
        const query = {
          q: idArray,
          filter: [],
        };
        const getAllPinnedDocRes = await getAllPinnedDoc(query);
        console.log("getAllPinnedDocRes", getAllPinnedDocRes);
        docfromsolr = getAllPinnedDocRes.data.response.docs;
        docfrommongo.map((data) => {
          let pindata = docfromsolr.filter((obj) => obj.id === data.documentId);
          data.searchResults = pindata[0];
        });
        // setPinnedDocs(docfrommongo);
        props.setPinnedSearches(docfrommongo);

      } catch (error) {
        console.error('Error fetching document:', error);
      }
      setDataLoaded(true);
    };
    id && fetchDocument();
  }, [id]);

  // main search result detail page where all details of items will be displayed
  return (
    <div className="bg-[#FAFAFA]">
      <Navbar />

      <WasHelpFull />

      { dataLoaded && <ResultSearchDetailBox /> }
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    searchedWord: state.searchedWord,
    pinnedSearches: state.pinnedSearches,
    docDetail: state.docDetail,
  };
};

export default connect(mapStateToProps, {
  setPinnedSearches,
  storeDocDetail,
  storeOutline,
  storeFileLink,
})(SearchResultDetailPage);
