import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { Accordion, Icon } from "semantic-ui-react";

const FeedbackList = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const onClickSection = (e, props) => {
    let sectionIndex = props.index;
    let newActiveIndex = activeIndex === sectionIndex ? -1 : sectionIndex;
    setActiveIndex(newActiveIndex);
  };
  const renderVideoTypes = ["mp4", "mov", "MP4", "MOV"];
  const renderImageType = ["png", "PNG", "jpeg", "jpg", "JPG", "JPEG"];
  const ref = useRef(null);
  const feedbackList = Object.values(props.feedbackList);

  // Render the videos aganist the selected step
  const renderDownloadedVideos = (feedback) => {
    var displayVideos = [];
    if (feedback._id.length > 0) {
      // Render files downloaded
      for (let i = 0; i < feedback.media.length; i++) {
        const mediaType = feedback.media[i].fileName.split(".").pop();
        if (renderVideoTypes.includes(mediaType)) {
          displayVideos.push(
            <video className="video-display" controls>
              <source
                className="video-display"
                src={feedback.media[i].url}
                alt=""
                type="video/mp4"
              />
            </video>
          );
        }
      }
    }
    return displayVideos;
  };
  const renderDownloadedImage = (feedback) => {
    var displayImages = [];
    if (feedback._id.length > 0 && feedback.media.length > 0) {
      // Render files downloaded
      for (let i = 0; i < feedback.media.length; i++) {
        const mediaType = feedback.media[i].fileName.split(".").pop();
        if (renderImageType.includes(mediaType)) {
          displayImages.push(
            <img
              className="feedback-img"
              src={feedback.media[i].url}
              onClick={() => {
                props.mediaFullScreen(feedback.media[i]);
              }}
            />
          );
        }
      }
    }
    return displayImages;
  };

  return (
    <>
      <div ref={ref} style={{ padding: ".5rem" }}>
        {feedbackList.map((feedback) => {
          return (
            <>
              <br />
              <Accordion fluid styled>
                <Accordion.Title active={true}>
                  <Icon
                    name="chevron down"
                    index={1}
                    onClick={onClickSection}
                    className="dropdown-icon"
                  />
                  {feedback.typeOfFeedback}
                </Accordion.Title>
                <Accordion.Content active={true}>
                  <p style={{ fontSize: "14px" }}>
                    {feedback.summaryOfFeedback}
                  </p>
                  <h4>Description</h4>
                  <p style={{ fontSize: "14px" }}>{feedback.brief}</p>
                  {feedback.media?.length > 0 && (
                    <>
                      <h4>Attachments</h4>
                      <ul style={{ listStyle: "none" }}>
                        {renderDownloadedImage(feedback).length > 0 &&
                          feedback.media?.length > 0 && (
                            <li>{renderDownloadedImage(feedback)}</li>
                          )}
                        {renderDownloadedVideos(feedback).length > 0 &&
                          feedback.media?.length > 0 && (
                            <li>{renderDownloadedVideos(feedback)}</li>
                          )}
                      </ul>
                    </>
                  )}
                </Accordion.Content>
              </Accordion>
            </>
          );
        })}
      </div>
    </>
  );
};

export default FeedbackList;
