import React, { useState } from "react";
import Logo from "../Logo/Logo";
import Useroptions from "../../components/Useroptions/Useroptions";

import KeycloakUserService from "../../keycloak/KeycloakUserService";
import { useNavigate } from "react-router";

const Navbar = (props) => {
  const [show, setShow] = useState("hide");
  const [btn, setBtn] = useState(0);
  const navigate = useNavigate();

  return (
    // this component is the navbar of the app
    <div className="navbar">
      <div className="ml-[1.1rem]  md:ml-2 mt-7 md:mt-5">
        <Logo />
      </div>
      <div className={`flex flex-row ml-auto block`}></div>
      <div className={`ml-auto `}>
        <Useroptions />
      </div>
    </div>
  );
};

export default Navbar;
