import React, { useState } from "react";
import KeycloakUserService from "../../keycloak/KeycloakUserService";
import { useNavigate } from "react-router";
import { connect } from "react-redux";
import { saveSearchWord, setPinnedSearches } from "../../redux/actions";
import { logUserAction } from "../apiCall";

const UserBtns = (props) => {
  const [btn, setBtn] = useState(0);
  function setBgBtn(num) {
    setBtn(num);
    navigate("/allpinnedsearches");
  }
  const navigate = useNavigate();
  function logout() {
    logUserAction({
      documentId: props.docDetail?.authdoc ? JSON.parse(props.docDetail?.authorData)?.id : props.docDetail?.id,
      route: 'closedocument',
    });
    navigate("/");
    localStorage.clear();
    props.saveSearchWord("");
    props.setPinnedSearches([]);
    sessionStorage.clear();
    KeycloakUserService.doLogout();
  }
  return (
    <div className="absolute top-[3.6rem] md:top-[4.8rem] right-[1.6rem] md:right-[1rem] flex flex-col h-[60px] md:h-[80px]  w-[135px] md:w-[210px] pt-1 md:pt-3 bg-white text-xs rounded-xl border drop-shadow mt-1 bg-white z-20">
      <div
        onClick={(e) => setBgBtn(e, 1)}
        className={`${
          props.newPath === "/allpinnedsearches"
            ? "userOptionsBtnsClicked  text-sky-700"
            : ""
        }`}
      >
        <span className="material-symbols-outlined userOptionsBtns ml-5 text-[10px] md:text-[12px] ">
          search
        </span>{" "}
        <span className="ml-[-2px] md:ml-2 font-inter text-[8px] md:text-[12px]">
          Pinned Searches
        </span>
      </div>

      <div
        onClick={() => logout()}
        className={`${
          btn === 4 ? "userOptionsBtnsClicked text-sky-700" : ""
        }  mt-[-8px] md:mt-[-2px]`}
      >
        <span className="material-symbols-outlined userOptionsBtns ml-5 text-[10px] md:text-[12px] ">
          logout
        </span>{" "}
        <span className="ml-[-2px] md:ml-2  font-inter text-[8px] md:text-[12px]">
          Logout
        </span>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    newPath: state.newPath,
  };
};

export default connect(mapStateToProps, {
  saveSearchWord,
  setPinnedSearches,
})(UserBtns);
