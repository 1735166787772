import React from "react";
import { Popup } from "semantic-ui-react";

const RenderFeedbackMediaComp = ({
  fileDetails,
  mediaFullScreen,
  removeFile,
  Image,
}) => {
  return (
    <div>
      <Popup
        className="upload-media-popup"
        trigger={
          <div className="render-uploadmedia-container">
            {Image ? (
              <img
                className="image-display-feedback"
                src={URL.createObjectURL(fileDetails)}
                alt=""
                onClick={() => {
                  mediaFullScreen(fileDetails);
                }}
              />
            ) : (
              <video
                className="video-display"
                controls
                onClick={() => {
                  mediaFullScreen(fileDetails);
                }}
              >
                <source
                  className="video-display"
                  src={URL.createObjectURL(fileDetails)}
                  alt=""
                  type="video/mp4"
                />
              </video>
            )}
            <button
              className={
                Image ? "close-button-position" : "video-close-button-position"
              }
              onClick={() => removeFile(fileDetails.name, "fileDetails")}
            >
              X
            </button>
          </div>
        }
        position="left center"
      >
        {fileDetails.name}
      </Popup>
    </div>
  );
};

export default RenderFeedbackMediaComp;
